import React from 'react'

const usePageMeta = (title, description) => {
  const defaultTitle = 'Steam Playtime'
  const defaultDesc = 'Get your Steam account in just one click. Check your game cost per hour and playtime. Compare your profile and games with your friends.'

  React.useEffect(() => {
    document.title = title ? `${title} - ${defaultTitle}` : defaultTitle
    document.querySelector("meta[name='description']").setAttribute('content', description || defaultDesc)
  }, [defaultTitle, title, defaultDesc, description])
}

export default usePageMeta
