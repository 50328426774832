import React from 'react'
import { useTranslation } from 'react-i18next'
import 'flag-icons/css/flag-icons.min.css'
import '@fontsource/oswald'
import '../../assets/css/summary.css'

import { Col, Container, Row } from 'react-bootstrap'
import NotFound from '../errors/NotFound'
import LoadingSummary from '../loading/profile/LoadingSummary'
import Player from './resume/Player'
import Avatar from './resume/Avatar'

export default function Summary ({ isLoaded, userExists, profile }) {
  const { t } = useTranslation('global')

  const SummaryData = () => {
    return (
      <section className='jumbotron text-center container-profile-info'>
        <Container fluid>
          <Row className='align-items-center'>
            <Col lg={6}>
              <h1 className='jumbotron-heading align-items-center'>{t('profile.your_games')} <span>{t('profile.your_games_cost_hour')}</span></h1>
            </Col>
            <Col lg={6}>
              <Row className='align-items-center'>
                <Col lg={10} xs={10} className='text-end'>
                  <Player profile={profile} />
                </Col>
                <Col lg={2} xs={2} className='text-start justify-content-center'>
                  <Avatar profile={profile} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  if (isLoaded && !userExists) {
    return <NotFound />
  } else if (isLoaded && userExists) {
    return <SummaryData />
  } else {
    return <LoadingSummary />
  }
}
