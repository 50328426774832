import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { toLocale } from '../controllers/converter/money.controller'
import { minutesToHours } from '../controllers/converter/playtime.controller'
import ReactTimeAgo from 'react-time-ago'
import { useTranslation } from 'react-i18next'
import LoadingDots from './loading/global/LoadingDots'
import NotFound from './errors/NotFound'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import { topGamesConfig } from '../config/charts'
import { mostPlayedGamesPercent } from '../controllers/charts.controller'
import NoData from './errors/NoData'
import usePageMeta from '../controllers/pageMeta.controller'

export default function Stats () {
  const { t, i18n } = useTranslation('global')
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [hasGames, setHasGames] = React.useState(false)
  const [mostPlayedGames, setMostPlayedGames] = React.useState({ options: {}, series: [] })
  const [topPlayers, setTopPlayers] = React.useState([])
  const [opt, setOpt] = React.useState({})
  const [hasData, setHasData] = React.useState(true)

  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

  React.useEffect(() => {
    let status
    fetch(`${process.env.REACT_APP_BASE_URL_API}/charts`, {
      mode: 'cors'
    }).then(res => {
      status = res.status
      return res.json()
    }).then((data) => {
      if (status === 200) {
        if (data.mostPlayedGames === null) {
          setHasData(false)
          setIsLoaded(true)
          return
        }

        data.mostPlayedGames.forEach(game => {
          game.playtime = minutesToHours(game.playtime)
        })

        const percents = mostPlayedGamesPercent(data.mostPlayedGames)

        setMostPlayedGames({
          labels: data.mostPlayedGames.map(game => game.name),
          datasets: [
            {
              data: data.mostPlayedGames.map(game => game.playtime),
              backgroundColor: topGamesConfig.backgroundColor,
              borderColor: topGamesConfig.borderColor,
              hoverBackgroundColor: topGamesConfig.hoverBackgroundColor,
              borderWidth: topGamesConfig.borderWidth
            }
          ]
        })

        setOpt({
          onResize: topGamesConfig.onResize,
          plugins: topGamesConfig.getPlugins(percents),
          cutout: topGamesConfig.cutout
        })

        setTopPlayers(data.topPlayersByPlaytime)

        setIsLoaded(true)
        setHasGames(true)
      } else {
        setIsLoaded(true)
        setHasGames(false)
      }
    })
  }, [])

  // TODO: Create translation with variables {} and use it
  // Create Player component looping topPlayers variable from state
  const Player = () => {
    return topPlayers.map((player, index) => {
      return (
        <tr key={index}>
          <td className='game-position align-middle text-center'><h3>{index + 1}</h3></td>
          <td>
            <img src={player.avatar} alt='avatar' className='img img-responsive rounded-circle' />
            <a href={`${process.env.REACT_APP_STEAM_PROFILE_LINK}${player.steam_id}`} target='_blank' rel='noreferrer' className='game-name d-inline text-decoration-none color-initial'>{player.name}</a>
          </td>
          <td className='text-center align-middle'>
            {player.stats && `${minutesToHours(player.stats.total_playtime)}h`}
          </td>
          <td className='text-center align-middle'>
            {player.stats && toLocale(player.stats.total_price, i18n.resolvedLanguage, true, 0)}
          </td>
          <td className='text-center align-middle'>
            <ReactTimeAgo date={Date.parse(player.register_date)} locale={i18n.resolvedLanguage} />
          </td>
        </tr>
      )
    })
  }

  const Charts = () => {
    return (
      <>
        <section className='jumbotron jumbotron-compare text-center container-compare-info'>
          <div className='container-fluid'>
            <div className='align-items-center row'>
              <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
                <span className='compare-title align-bottom'>{t('stats.most_played_games')}</span>
              </div>
            </div>
          </div>
        </section>

        <Container className='chart-container p-4' style={{ maxWidth: '700px' }}>
          <Doughnut data={mostPlayedGames} options={opt} />
        </Container>

        <section className='jumbotron jumbotron-compare text-center container-compare-info'>
          <div className='container-fluid'>
            <div className='align-items-center row'>
              <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
                <span className='compare-title align-bottom'>{t('charts.top_players_by_playtime')}</span>
              </div>
            </div>
          </div>
        </section>
        <br />

        <Container>
          <Row>
            <Col lg={12}>
              <Table striped responsive>
                <thead>
                  <tr className='table-dark text-center'>
                    <th>#</th>
                    <th>{t('stats.player')}</th>
                    <th>{t('stats.total_playtime')}</th>
                    <th>{t('stats.total_spent')}</th>
                    <th>{t('stats.user_since')}</th>
                  </tr>
                </thead>
                <tbody>
                  <Player topPlayers={topPlayers} i18n={i18n} t={t} />
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  usePageMeta(t('title.stats'), t('description.stats'))

  if (!isLoaded) {
    return (
      <LoadingDots />
    )
  } else if (isLoaded && hasGames) {
    return (
      <Charts />
    )
  } else if (isLoaded && !hasData) {
    return (
      <NoData />
    )
  } else if (isLoaded && !hasGames) {
    return (
      <NotFound />
    )
  }
}
