import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar, Container, Image, Form, FormControl, Button, Nav, NavDropdown } from 'react-bootstrap'
import logo from '../assets/images/theme/steam-playtime-400x40-white.png'
import '../assets/css/header.css'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import CurrencyContext from './Currency'

export default function Header () {
  const { t, i18n } = useTranslation('global')
  const userName = Cookies.get('uname') || 'Profile'
  const userId = Cookies.get('uid')

  const { currency, setCurrency } = React.useContext(CurrencyContext)

  const navigate = useNavigate()

  const redirectProfile = () => {
    const headerSteamId = document.getElementById('headerSteamId').value

    if (headerSteamId) {
      navigate(`/profile/${headerSteamId}`)
    }
  }

  // TODO: Implement LOGOUT
  // Login button if userId is set
  const loginButton = userId
    ? (
      <Link to={`/profile/${userId}`}>
        <Button variant='outline-primary' className='btn-login btn-search'>{decodeURI(userName)}</Button>
      </Link>
      )
    : (
      <a href={`${process.env.REACT_APP_BASE_URL_API}/auth/steam`}>
        <Button variant='outline-primary' className='btn-login btn-search'>{t('auth.login')}</Button>
      </a>
      )

  const Currencies = () => {
    const currencies = process.env.REACT_APP_CURRENCIES.split(',') || ['EUR', 'USD', 'GBP']
    if (currencies.length > 0) {
      return (
        <>
          {currencies.map((currency, index) => {
            return (
              <NavDropdown.Item key={index} onClick={() => setCurrency(currency)} className='dropdown-item'>{currency}</NavDropdown.Item>
            )
          })}
        </>
      )
    } else {
      return (
        <>
          <NavDropdown.Item onClick={() => setCurrency('EUR')} className='dropdown-item'>EUR</NavDropdown.Item>
          <NavDropdown.Item onClick={() => setCurrency('USD')} className='dropdown-item'>USD</NavDropdown.Item>
        </>
      )
    }
  }

  return (
    <div>
      <Navbar bg='dark' variant='dark' className='top-header' expand='lg'>
        <Container fluid>
          <Link className='navbar-brand' to='/'>
            <Image fluid alt='Logo' src={logo} className='d-inline-block align-top' />
          </Link>

          <Navbar.Toggle />

          <Navbar.Collapse className='justify-content-end'>
            <Nav>
              <NavDropdown title={currency} id='basic-nav-dropdown' className='nav-item'>
                <Currencies />
              </NavDropdown>
              <NavDropdown title={(i18n.resolvedLanguage).toUpperCase()} id='basic-nav-dropdown' className='nav-item'>
                <NavDropdown.Item onClick={() => i18n.changeLanguage('en')} className='dropdown-item'>English</NavDropdown.Item>
                <NavDropdown.Item onClick={() => i18n.changeLanguage('es')} className='dropdown-item'>Spanish</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={t('menu.keys')} id='basic-nav-dropdown' className='nav-item'>
                <NavDropdown.Item href='/news'>{t('menu.free_games_offers')}</NavDropdown.Item>
                <NavDropdown.Item href='/keys/top'>{t('menu.cheap_keys')}</NavDropdown.Item>
                <NavDropdown.Item href='/keys/search'>{t('menu.search_keys')}</NavDropdown.Item>
              </NavDropdown>
              <Link className='nav-item nav-link' to='/stats'>{t('menu.stats')}</Link>
              <Link className='nav-item nav-link' to='/about'>{t('menu.about_us')}</Link>
            </Nav>

            <Form className='d-flex pe-lg-3 ps-lg-3 pb-3 pb-sm-0' onSubmit={e => { e.preventDefault() }}>
              <FormControl type='search' placeholder={t('search.by_steamid')} id='headerSteamId' className='me-2' aria-label='Search' />
              <Button variant='outline-primary' className='btn-search' onClick={redirectProfile}>{t('header.search')}</Button>
            </Form>

            <Form className='d-flex'>
              {loginButton}
            </Form>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
