import { Container } from 'react-bootstrap'
import React from 'react'
import '../../assets/css/error/notFound.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import error404 from '../../assets/images/theme/404.png'

export default function NoGames () {
  const { t } = useTranslation('global')

  return (
    <Container className='mt-3'>
      <link href='https://fonts.googleapis.com/css?family=Kanit:200' rel='stylesheet' />
      <div id='notfound'>
        <div className='notfound'>
          <div>
            <img alt='404 Error' src={error404} />
          </div>
          <h2>{t('error.profile_no_games_private')}</h2>
          <p>
            <Link to='/about#privacy'>{t('faq.change_profile_privacy')}</Link>
            <br />
            <Link to='/about#find_profile'>{t('faq.search_steam_id')}</Link>
            <br />
            <Link to='/'>{t('info.go_homepage')}</Link>
          </p>
        </div>
      </div>
    </Container>
  )
}
