import { minutesToHours } from '../../controllers/converter/playtime.controller'
import { toLocale } from '../../controllers/converter/money.controller'
import ReactTimeAgo from 'react-time-ago'
import React from 'react'
import { capitalizeFirstLetter } from '../../controllers/converter/string.controller'

const Player = ({ topPlayers, i18n, t, profileId = null }) => {
  return topPlayers.map((player, index) => {
    const isUser = profileId && player.steam_id === profileId
    const idx = isUser ? capitalizeFirstLetter(t('general.you')) : index + 1

    return (
      <tr key={index}>
        <td className='game-position align-middle text-center'><h3>{idx}</h3></td>
        <td>
          <img src={player.avatar} alt='avatar' className='img img-responsive rounded-circle' />
          <a
            href={`${process.env.REACT_APP_STEAM_PROFILE_LINK}${player.steam_id}`} target='_blank' rel='noreferrer'
            className='game-name d-inline text-decoration-none color-initial'
          >{player.name}
          </a>
        </td>
        <td className='text-center align-middle'>
          {player.stats && `${minutesToHours(player.stats.total_playtime)}h`}
        </td>
        <td className='text-center align-middle'>
          {player.stats && toLocale(player.stats.total_price, i18n.resolvedLanguage, true, 0)}
        </td>
        <td className='text-center align-middle'>
          <ReactTimeAgo date={Date.parse(player.register_date)} locale={i18n.resolvedLanguage} />
        </td>
      </tr>
    )
  })
}

export default Player
