import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { formatDecimal, toLocale } from './converter/money.controller'
import { useTranslation } from 'react-i18next'
import { getAffiliateCode } from './key.controller'

const Hltb = ({ game, t }) => {
  return typeof game.hltb !== 'undefined' && typeof game.hltb.main !== 'undefined'
    ? (
      <div>
        <span>{t('duration.game_duration')} {game.hltb.main}h</span>
        <OverlayTrigger
          trigger={['focus', 'hover']} placement='top' overlay={
            <Popover id='popover-basic'>
              <Popover.Header as='h3'>{t('duration.game_duration')}</Popover.Header>
              <Popover.Body>
                {t('duration.base_duration')} {game.hltb.main}h
                <br />
                {t('duration.extras_duration')} {game.hltb.extra}h
                <br />
                {t('duration.completionist_duration')} {game.hltb.completionist}h
              </Popover.Body>
            </Popover>
        }
        >
          <span className='material-icons align-bottom ms-1 game-hltb'>access_time</span>
        </OverlayTrigger>
      </div>
      )
    : game.genres
}

const GameKey = ({ game, t }) => {
  const { i18n } = useTranslation()

  if (typeof game.key !== 'undefined') {
    return (
      <a
        href={`${game.key.link}${getAffiliateCode(game.key.store)}`} className='game-link' target='_blank'
        rel='noreferrer'
      >{t('buy.for')} {toLocale(game.key.price, i18n.resolvedLanguage, true, 2)} {t('general.at')} {game.key.store}
      </a>
    )
  } else {
    return game.publishers
  }
}

const GameCost = ({ game, compare = null }) => {
  const { i18n } = useTranslation()
  if (compare) {
    return typeof game.cost2 !== 'undefined'
      ? (
        <>{toLocale(game.cost2, i18n.resolvedLanguage, true, 2, false)}</>
        )
      : (
        <></>
        )
  }

  return typeof game.cost !== 'undefined'
    ? (
      <>{toLocale(game.cost, i18n.resolvedLanguage, true, 2, false)}</>
      )
    : formatDecimal(0, 2)
}

export {
  GameKey,
  GameCost,
  Hltb
}
