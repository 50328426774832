import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'flag-icons/css/flag-icons.min.css'
import '../../assets/css/games.css'
import { Button, Col, Container, FormControl, Row, Table } from 'react-bootstrap'
import LoadingGames from '../loading/profile/LoadingGames'
import CompareGame from './CompareGame'
import { useSortableData } from '../../controllers/search.controller'
import { alphaNumericCleaner } from '../../controllers/inputCleaner/inputCleaner.controller'
import Paginate from '../profile/Paginate'

export default function CompareGames ({ isLoaded, userExists, profiles }) {
  const { t } = useTranslation('global')
  const profile = profiles.profile
  const profile2 = profiles.profile2

  const navigate = useNavigate()

  const GameTable = (userGames) => {
    const { items, requestSort, sortConfig } = useSortableData(userGames.games)
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return
      }
      return sortConfig.key === name ? sortConfig.direction : undefined
    }

    const games = items.map((game) => {
      return <CompareGame game={game} key={game.index} profile={profile} />
    })

    return (
      <>
        <Table striped responsive>
          <thead className='thead-dark text-center'>
            <tr>
              <th>#</th>
              <th>{t('profile.table_games')}</th>
              <th onClick={() => requestSort('playtime')} className={`${getClassNamesFor('playtime')} sortable`}>{t('profile.table_hours')} ({profile.name})</th>
              <th onClick={() => requestSort('playtime2')} className={`${getClassNamesFor('playtime2')} sortable`}>{t('profile.table_hours')} ({profile2.name})</th>
              <th onClick={() => requestSort('cost')} className={`${getClassNamesFor('cost')} sortable`}>{t('profile.table_cost_hour')}</th>
              <th onClick={() => requestSort('cost2')} className={`${getClassNamesFor('cost2')} sortable`}>{t('profile.table_cost_hour')}</th>
            </tr>
          </thead>
          <tbody>
            {games}
          </tbody>
        </Table>
      </>
    )
  }

  const [search, setSearch] = React.useState('')

  // Set search in lower case
  const handleSearch = (event) => setSearch(event.target.value.toLowerCase())

  // Search games
  let userGames = isLoaded && userExists ? profiles.profile.userGames.filter((item) => alphaNumericCleaner(item.game.name.toLowerCase()).includes(search)) : null

  const [pagination, setPagination] = React.useState([0, parseInt(process.env.REACT_APP_PAGINATION_MAX_GAMES_PER_PAGE)] || 50)
  // Get first 50 games from userGames
  userGames = userGames ? userGames.slice(pagination[0], pagination[1]) : null

  // Go to the same route with /charts
  const goCharts = () => {
    const url = window.location.pathname
    const urlTrimmed = url.replace(/\/$/, '')
    navigate(`${urlTrimmed}/charts`)
  }

  if (!isLoaded) {
    return <LoadingGames />
  } else if (isLoaded && userExists && (profile.userGames).length > 0 && (profile2.userGames).length > 0) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='float-start'>
              <Button variant='outline-success' onClick={goCharts}>See charts <span className='material-icons align-bottom ms-1'>bar_chart</span></Button>
            </div>
            <div className='float-end mb-3'>
              <label>{t('header.search')}:</label>
              <FormControl onChange={handleSearch} type='text' className='form-control-sm d-inline-block ms-2 w-auto' />
            </div>
          </Col>
        </Row>

        <GameTable games={userGames} />
        <Paginate userGames={profiles.profile.userGames} setPagination={setPagination} />
      </Container>
    )
  }
}
