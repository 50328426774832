const steamLevelStyle = (level) => {
  return level < 100 ? Math.floor(level / 10) * 10 : Math.floor(level / 100) * 100
}

const steamSubLevelStyle = (level) => {
  return level < 100 ? null : Math.floor(level % 100 / 10) * 10
}

export {
  steamLevelStyle,
  steamSubLevelStyle
}
