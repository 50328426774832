const defaultCurrency = 'EUR'

const getCurrency = () => {
  return window.localStorage.getItem('currency') || defaultCurrency
}

const setCurrency = (currency) => {
  window.localStorage.setItem('currency', currency)
  window.location.reload(false)
}

export {
  getCurrency,
  setCurrency
}
