import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Summary from './Summary'
import Compare from './Compare'
import Games from './Games'
import Stats from './Stats'
import { notFoundMessage } from '../../config/constants'
import usePageMeta from '../../controllers/pageMeta.controller'

export default function Profile () {
  const { t } = useTranslation('global')
  const params = useParams()
  const profileId = params.profile

  const [profile, setProfile] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [userExists, setUserExists] = React.useState(true)
  const [hasGames, setHasGames] = React.useState(true)

  React.useEffect(() => {
    setIsLoaded(false)
    fetch(`${process.env.REACT_APP_BASE_URL_API}/profile/${profileId}`, {
      mode: 'cors'
    }
    )
      .then(res => res.json())
      .then((data) => {
        if (data.error && data.error === notFoundMessage) {
          setUserExists(false)
        }

        if (data.userGames && data.userGames.length === 0) {
          setHasGames(false)
        }

        setProfile(data)
        setIsLoaded(true)
      })
  }, [profileId])

  usePageMeta(t('title.profile'), t('description.profile'))

  return (
    <div>
      <Summary profile={profile} userExists={userExists} hasGames={hasGames} isLoaded={isLoaded} />
      <Compare profile={profile} userExists={userExists} hasGames={hasGames} />
      <Stats stats={profile.stats} userExists={userExists} hasGames={hasGames} isLoaded={isLoaded} />
      <Games profile={profile} userExists={userExists} hasGames={hasGames} isLoaded={isLoaded} />
    </div>
  )
}
