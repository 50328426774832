import jwt from 'jwt-decode'
import Cookies from 'js-cookie'

// Check if url has parameter token with react
// If it has, decompress it and save into 3 cookies
export default function loginDetector () {
  // If we don't have bt-steam cookie
  if (!Cookies.get('bt-steam') || Cookies.get('bt-steam') === 'undefined') {
    const token = window.location.search.split('=')[1]

    if (token) {
      // Decode, set try catch in case of error
      try {
        const decoded = jwt(token)

        // Set cookies
        Cookies.set('bt-steam', token)
        Cookies.set('uname', decoded.name)
        Cookies.set('uid', decoded.id)
      } catch (ignored) {}
    }
  }
}
