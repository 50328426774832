import '../../../assets/css/loading/dots.css'

export default function LoadingDots () {
  return (
    <div id='dots'>
      <div className='loader-container'>
        <div className='loader' />
      </div>
    </div>
  )
}
