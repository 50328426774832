import React from 'react'
import { Image } from 'react-bootstrap'

export default function ImageWithFallback (fallback, game) {
  const src = `${process.env.REACT_APP_STEAM_IMAGE_URL}${game.steam_id}/header.jpg`

  function addDefaultSrc (ev) {
    ev.target.src = fallback
  }

  return <Image loading='lazy' src={src || fallback} onError={addDefaultSrc} className='game-image' />
}
