// Convert cents to dollars, with no decimals as default parameter
import { getCurrency } from '../currency.controller'
import * as rates from '../../rates/rates_eur.json'

const centsToDollars = (cents, decimals = 0, removeZeros = false) => {
  const result = (cents / 100).toFixed(decimals)
  return removeZeros ? removeLeadingZeros(result) : result
}

const formatDecimal = (number, decimals = 0) => {
  return number.toFixed(decimals)
}

const removeLeadingZeros = (number) => {
  return number.replace(/\.00$/, '')
}

const toAbsolute = (number) => {
  return Math.abs(number)
}

const toLocale = (number, language = 'en', toDollar = false, decimals = 2, showCurrency = true, removeZeros = false, thousandSeparator = true) => {
  let numberFormatted = number
  if (toDollar) {
    numberFormatted = number / 100
  }

  // Convert currency from Euro to current currency
  numberFormatted = convertCurrency(numberFormatted)

  const formatter = Intl.NumberFormat(language, {
    style: 'currency',
    currency: getCurrency(),
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })

  numberFormatted = showCurrency
    ? formatter.format(numberFormatted)
    : formatter.formatToParts(numberFormatted).map(
      p => p.type !== 'literal' && p.type !== 'currency' ? p.value : ''
    ).join('')

  if (!thousandSeparator) {
    numberFormatted = numberFormatted.replace(/,(?=.*\.\d+)/g, '')
  }

  return removeZeros ? removeLeadingZeros(numberFormatted) : numberFormatted
}

const convertCurrency = (number, toDollar = false, reverse = false) => {
  if (toDollar) {
    number = number / 100
  }

  // Convert currency from Euro to current currency
  if (getCurrency() !== 'EUR') {
    const rate = rates[getCurrency()]
    number = reverse ? number / rate : number * rate
  }

  return number
}

export {
  centsToDollars,
  formatDecimal,
  toAbsolute,
  toLocale,
  convertCurrency
}
