import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row, Badge } from 'react-bootstrap'
import '../../assets/css/news.css'
import { formatFullDate } from '../../controllers/converter/date.controller'
import usePageMeta from '../../controllers/pageMeta.controller'

export default function News () {
  const { t, i18n } = useTranslation('global')

  const [news, setNews] = React.useState([])

  React.useEffect(() => {
    let status
    fetch(`${process.env.REACT_APP_BASE_URL_API}/news`, {
      mode: 'cors'
    }).then(res => {
      status = res.status
      return res.json()
    }).then((data) => {
      if (status === 200) {
        setNews(data)
      }
    })
  }, [])

  const New = () => {
    return (
      <div>
        {news.map((_new, index) => (
          <Row className='text-muted pt-3' key={index}>

            <Row className='align-items-center container-header-news'>
              <Col sm={1} className='container-name-news'>
                <span className='align-top date-news'>
                  <Badge bg='danger'>{_new.type === 'free' ? t('news.free') : t('news.offer')}</Badge>
                </span>
              </Col>
              <Col sm={9} className='container-date-news'>
                <a href={_new.link} className='d-block text-gray-dark offer-name text-start' target='_blank' rel='noreferrer'>{_new.name}</a>
              </Col>
              <Col sm={2} className='container-date-news text-end'>
                <span className='offer-date'>{formatFullDate(_new.created_on, i18n.language)}</span>
              </Col>
            </Row>

          </Row>
        ))}
      </div>
    )
  }

  usePageMeta(t('title.news'), t('description.news'))

  return (
    <>
      <section className='jumbotron jumbotron-compare text-center container-compare-info mb-5'>
        <div className='container-fluid'>
          <div className='align-items-center row'>
            <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
              <span className='compare-title align-bottom'>{t('news.games')}</span>
            </div>
          </div>
        </div>
      </section>

      <Container className='news'>
        <div className='d-flex align-items-center p-3 my-3 text-white-50 bg-purple rounded box-shadow news-header'>

          <div className='lh-100'>
            <h6 className='mb-0 text-white lh-100'>{t('news.games_warning')}</h6>
          </div>
        </div>

        <div>
          <h6 className='border-bottom border-gray pb-2 mb-0'>{t('news.games_remind')}</h6>
          <New />
        </div>
      </Container>
    </>
  )
}
