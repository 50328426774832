// Get the key store, and return the affilliates link
const getAffiliateCode = (store) => {
  // TODO: Set global file with constants of stores
  const affliateLinks = {
    Eneba: process.env.REACT_APP_AFFILLIATE_ENEBA_KEY,
    HRK: process.env.REACT_APP_AFFILLIATE_HRK_KEY
    // 'Instant Gaming': process.env.REACT_APP_AFFILLIATE_IG_KEY
  }

  return affliateLinks[store] || ''
}

export {
  getAffiliateCode
}
