import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../assets/css/compare.css'
import { Button, Col, Container, Form, FormControl, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function Compare ({ userExists, hasGames, profile }) {
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const redirectCompare = () => {
    const headerSteamId = document.getElementById('compareSteamId').value

    // Compare this profile with headerSteamId
    if (headerSteamId) {
      navigate(`/profile/${profile.steam_id}/${headerSteamId}`)
    }
  }

  if (userExists && hasGames) {
    return (
      <section className='jumbotron jumbotron-compare text-center container-compare-info'>
        <Container fluid>
          <Row className='align-items-center'>
            <Col className='justify-content-center jumbotron-heading align-items-center my-auto'>
              <span className='compare-title align-bottom'>{t('app.compare_with_friends')}</span>
              <Form className='form-inline pe-lg-3 ps-lg-3 pb-3 pb-sm-0' onSubmit={e => { e.preventDefault() }}>
                <FormControl type='search' placeholder={t('search.by_steamid')} id='compareSteamId' className='form-inline me-3 ms-4' aria-label='Search' />
                <Button variant='outline-primary' id='search-compare' className='btn-search' onClick={redirectCompare}>{t('header.compare')}</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}
