import { Col, Image, Placeholder, Row } from 'react-bootstrap'
import React from 'react'
import imageNotFound from '../../../assets/images/profile/image-not-found.png'

const LoadingGames = () => {
  const iterator = [...Array(6)]
  const loadingBlock = (i) =>
    <Col key={i} lg={6} xs={12} sm={12} className='col-game mx-auto col-game-keys'>
      <Row>
        <Col lg={4} xs={12} sm={12} className='container-game-image'>
          <Image src={imageNotFound} className='game-image' />
        </Col>
        <Col lg={8} xs={12} sm={12} className='container-game-info'>
          <Row>
            <Col lg={8} xs={12} sm={12}>
              <div>
                <Placeholder animation='glow'>
                  <Placeholder xs={6} className='float-start' />
                </Placeholder>
              </div>
              <br />

              <div>
                <Placeholder animation='glow'>
                  <Placeholder xs={3} className='float-start' />
                </Placeholder>
              </div>
              <br />

              <div>
                <Placeholder animation='glow'>
                  <Placeholder bg='primary' xs={2} className='float-start' />
                </Placeholder>
              </div>
            </Col>
            <Col lg={4} xs={12} sm={12}>
              <div>
                <br />
                <Placeholder animation='glow' className='d-flex align-items-center justify-content-center'>
                  <Placeholder bg='success' xs={6} className='float-end' />
                </Placeholder>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>

  return (
    <>
      {iterator.map((value, index) => loadingBlock(index))}
    </>
  )
}

export default LoadingGames
