import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Form, FormControl, Row } from 'react-bootstrap'
import topBanner from '../assets/images/index/background-index.jpg'
import steamGamesImage from '../assets/images/index/steam_games.jpg'
import steamSharingImage from '../assets/images/index/steam_sharing.jpg'
import steamGiftCardsImage from '../assets/images/index/steam_gift_cards.jpg'
import '../assets/css/home.css'
import 'material-icons/iconfont/filled.css'
import { useNavigate } from 'react-router-dom'
import usePageMeta from '../controllers/pageMeta.controller'

export default function Home () {
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const redirectProfile = () => {
    const headerSteamId = document.getElementById('steamId').value

    if (headerSteamId) {
      navigate(`/profile/${headerSteamId}`)
    }
  }

  usePageMeta(t('title.index'), t('description.index'))

  return (
    <div>
      <div className='masthead text-white text-center' style={{ backgroundImage: `url(${topBanner})` }}>
        <Container>
          <Row className='justify-content-md-center'>
            <Col lg={8}>
              <h1>{t('app.check_steam_value')}</h1>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col lg={6}>
              <Form className='d-flex m-3' onSubmit={e => { e.preventDefault() }}>
                <FormControl size='lg' type='search' id='steamId' placeholder={t('search.by_steamid')} className='me-2' aria-label='Search' />
                <Button size='lg' variant='primary' className='btn-search' onClick={redirectProfile}>{t('header.search')}</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <section className='features-icons bg-light text-center'>
        <Container>
          <Row>
            <Col lg={4}>
              <div className='features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3'>
                <div className='features-icons-icon d-flex'>
                  <i className='material-icons align-top icon-info-data m-auto text-primary icon-front'>search</i>
                </div>
                <h3 className='first-header-front'>{t('app.get_steam_value')}</h3>
                <p className='lead mb-0'>{t('app.get_steam_value_description')}</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className='features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3'>
                <div className='features-icons-icon d-flex'>
                  <i className='material-icons align-top icon-info-data m-auto text-primary icon-front'>view_list</i>
                </div>
                <h3>{t('app.compare_results')}</h3>
                <p className='lead mb-0'>{t('app.compare_results_description')}</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className='features-icons-item mx-auto mb-0 mb-lg-3'>
                <div className='features-icons-icon d-flex'>
                  <i className='material-icons align-top icon-info-data m-auto text-primary icon-front'>local_offer</i>
                </div>
                <h3>{t('app.latest_offers_keys')}</h3>
                <p className='lead mb-0'>{t('app.latest_offers_keys_description')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='showcase'>
        <div className='container-fluid p-0'>
          <div className='row no-gutters w-100'>
            <div
              className='col-lg-6 order-lg-2 text-white showcase-img'
              style={{ backgroundImage: `url(${steamGamesImage})` }}
            />
            <div className='col-lg-6 order-lg-1 my-auto showcase-text'>
              <h2>{t('app.get_steam_account_value')}</h2>
              <p className='lead mb-0'>{t('app.get_steam_account_value_description')}</p>
            </div>
          </div>
          <div className='row no-gutters w-100'>
            <div
              className='col-lg-6 text-white showcase-img'
              style={{ backgroundImage: `url(${steamSharingImage})` }}
            />
            <div className='col-lg-6 my-auto showcase-text'>
              <h2>{t('app.share_profile_friends')}</h2>
              <p className='lead mb-0'>{t('app.share_profile_friends_description')}</p>
            </div>
          </div>
          <div className='row no-gutters w-100'>
            <div
              className='col-lg-6 order-lg-2 text-white showcase-img'
              style={{ backgroundImage: `url(${steamGiftCardsImage})` }}
            />
            <div className='col-lg-6 order-lg-1 my-auto showcase-text'>
              <h2>{t('app.find_latest_offers')}</h2>
              <p className='lead mb-0'>{t('app.find_latest_offers_description')}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
