import { Col, Container, Image, Placeholder, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import unknownAvatar from '../../../assets/images/profile/unknown-avatar.jpg'

const LoadingSummary = () => {
  const { t } = useTranslation('global')

  return (
    <div>
      <section className='jumbotron text-center container-profile-info'>
        <Container fluid>
          <Row className='align-items-center'>
            <Col lg={6}>
              <h1 className='jumbotron-heading align-items-center'>{t('profile.your_games')} <span>{t('profile.your_games_cost_hour')}</span></h1>
            </Col>
            <Col lg={6}>
              <Row className='user-profile align-items-center h-100'>
                <Col lg={10} className='text-end'>
                  <div className=''>
                    <Placeholder animation='wave'>
                      <Placeholder xs={4} className='' />
                      <br />
                      <Placeholder xs={3} className='' />
                      <br />
                      <Placeholder xs={2} className='' />
                      <br />
                      <Placeholder xs={5} className='' />
                    </Placeholder>
                  </div>
                </Col>
                <Col lg={2} className='text-start justify-content-center'>
                  <Image className='img-responsive rounded-circle' src={unknownAvatar} width='64' height='64' />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default LoadingSummary
