import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../assets/css/stats.css'

import { Button, Card, Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import LoadingStats from '../loading/profile/LoadingStats'
import { minutesToHours } from '../../controllers/converter/playtime.controller'
import { toLocale } from '../../controllers/converter/money.controller'
import usePageMeta from '../../controllers/pageMeta.controller'

export default function Stats ({ isLoaded, userExists, hasGames, stats }) {
  const { t, i18n } = useTranslation('global')

  const [playedData, setPlayedData] = React.useState(false)
  const switchPlayedData = () => setPlayedData(!playedData)
  const totalPlaytime = () => minutesToHours(stats.total_playtime, 'ceil')
  const avgTimePlayed = () => minutesToHours(stats.avg_playtime, 'ceil')
  const totalPrice = () => toLocale(stats.total_price, i18n.resolvedLanguage, true, 0)
  const avgPrice = () => toLocale(stats.avg_price, i18n.resolvedLanguage, true, 2)
  const avgCost = () => toLocale(stats.avg_cost, i18n.resolvedLanguage, true, 2)

  const avgTimePlayedPlayed = () => minutesToHours(stats.played.avg_playtime, 'ceil')
  const avgPricePlayed = () => toLocale(stats.played.avg_price, i18n.resolvedLanguage, true, 2)
  const avgCostPlayed = () => toLocale(stats.played.avg_cost, i18n.resolvedLanguage, true, 2)

  const StatsData = () => {
    return (
      <div>
        <Container fluid className='mt-5 mb-5'>
          <Row className='d-flex justify-content-center'>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span style={!playedData ? {} : { display: 'none' }}>{avgCost()}/h</span>
                <span style={playedData ? {} : { display: 'none' }}>{avgCostPlayed()}/h</span>
                <span className='game-worth-label'>{t('data.cost')}</span>
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span style={!playedData ? {} : { display: 'none' }}>{avgPrice()}</span>
                <span style={playedData ? {} : { display: 'none' }}>{avgPricePlayed()}</span>
                <span className='game-worth-label'>{t('data.price_game')}</span>
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span style={!playedData ? {} : { display: 'none' }}>{avgTimePlayed()}h</span>
                <span style={playedData ? {} : { display: 'none' }}>{avgTimePlayedPlayed()}h</span>
                <span className='game-worth-label'>{t('data.hours_game')}</span>
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span>{totalPrice()}</span>
                <span className='game-worth-label'>{t('data.spent_games')}</span>
              </p>

            </Col>
            <Col sm={12} md={6} lg={2} className='text-center'>
              <p className='game-worth text-center'>
                <span>{totalPlaytime()}h</span>
                <span className='game-worth-label'>{t('data.hours_played')}</span>
              </p>
            </Col>

            <Col sm={12} md={6} lg={2} className='text-center'>
              <Card>
                <Card.Header>
                  <span style={!playedData ? {} : { display: 'none' }}>{t('data.view_played_games')}</span>
                  <span style={playedData ? {} : { display: 'none' }}>{t('data.view_all_games')}</span>

                  <OverlayTrigger
                    trigger={['focus', 'hover']} placement='top' overlay={
                      <Popover id='popover-basic'>
                        <Popover.Body style={!playedData ? {} : { display: 'none' }}>
                          {t('data.info_played_games')}
                        </Popover.Body>
                        <Popover.Body style={playedData ? {} : { display: 'none' }}>
                          {t('data.info_all_games')}
                        </Popover.Body>
                      </Popover>
                }
                  >
                    <span className='material-icons align-bottom ms-1 game-hltb'>access_time</span>
                  </OverlayTrigger>
                </Card.Header>
                <Card.Body>
                  <Button variant='dark' onClick={() => switchPlayedData()}>{t('data.change_data')}</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  usePageMeta(t('title.stats'), t('description.stats'))

  if (!isLoaded) {
    return <LoadingStats />
  } else if (isLoaded && hasGames && userExists) {
    return <StatsData />
  }
}
