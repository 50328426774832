import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Form, InputGroup, Row } from 'react-bootstrap'
import '../../assets/css/keys.css'
import Game from './Game'
import usePageMeta from '../../controllers/pageMeta.controller'

export default function GamesKeysSearch () {
  const { t } = useTranslation('global')

  const [games, setGames] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [query, setQuery] = React.useState('')

  const searchGames = (name) => {
    fetch(`${process.env.REACT_APP_BASE_URL_API}/keys/search`, {
      method: 'POST',
      body: JSON.stringify({ name }),
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    )
      .then(res => res.json())
      .then((data) => {
        setGames(data)
        setIsLoaded(true)
      })
  }

  function NotFound () {
    return (
      <Container className='text-center'>
        <h3>{t('error.no_games_found')}</h3>
      </Container>
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (validateForm()) {
      searchGames(query)
    }
  }

  const validateForm = () => {
    return query.length > 0 && query.length < 200
  }

  const handleChange = (name) => {
    setQuery(name)
  }

  usePageMeta(t('title.keys_search'), t('description.keys_search'))

  return (
    <>
      <section className='jumbotron jumbotron-compare text-center container-compare-info mb-5'>
        <div className='container-fluid'>
          <div className='align-items-center row'>
            <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
              <span className='compare-title align-bottom'>{t('search.games_title')}</span>
            </div>
          </div>
        </div>
      </section>

      <Container className='keys-cheap'>
        <form onSubmit={handleSubmit}>
          <InputGroup className='mb-3'>
            <Form.Control
              placeholder={t('header.search')}
              aria-label={t('header.search')}
              aria-describedby='key-search'
              onChange={event => handleChange(event.target.value)}
            />
            <InputGroup.Text onClick={handleSubmit} id='key-search'><i className='material-icons align-top icon-info-data'>search</i></InputGroup.Text>
          </InputGroup>
        </form>
        <br />

        <Row>
          {isLoaded && games.length > 0 && <Game games={games} />}
          {isLoaded && games.error && <NotFound />}
        </Row>
      </Container>
    </>
  )
}
