import Cookies from 'js-cookie'
import { convertCurrency } from './converter/money.controller'

export default function useForm (price = null, gameId, steamId) {
  function handleChange (event) {
    if (Cookies.get('bt-steam') !== undefined && Cookies.get('bt-steam') !== '') {
      const { type, value } = event.target

      if (value * 100 !== price) {
        switch (type) {
          case 'number':
            price = Number(value * 100)
            break

          default:
            price = value
        }

        let status

        // Convert price to currency if is not in Euro
        price = convertCurrency(price, false, true)

        fetch(`${process.env.REACT_APP_BASE_URL_API}/profile/${steamId}/update-game-price`, {
          mode: 'cors',
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('bt-steam')}`,
            credentials: 'include'

          },
          body: JSON.stringify({ id: steamId, gameId, price })
        }
        ).then(res => {
          status = res.status
          return res.json()
        }).then((data) => {
          if (status === 200) {
            // TODO: Update the color of the next input of profile
          }
        })
      }
    } else {
      // Redirect to login
      window.location.assign(`${process.env.REACT_APP_BASE_URL_API}/auth/steam`)
    }
  }

  return {
    handleChange
  }
}
