import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../assets/css/stats.css'

import { Col, Container, Row } from 'react-bootstrap'
import { minutesToHours } from '../../controllers/converter/playtime.controller'
import { toLocale } from '../../controllers/converter/money.controller'
import LoadingStats from '../loading/profile/LoadingStats'
import NoGames from '../errors/NoGames'
import Player from '../profile/resume/Player'
import Avatar from '../profile/resume/Avatar'

export default function CompareStats ({ profiles, userExists, isLoaded }) {
  const { t, i18n } = useTranslation('global')

  const StatsData = () => {
    if ((profiles.profile.userGames).length <= 0 || (profiles.profile2.userGames).length <= 0) {
      return (
        <NoGames />
      )
    }

    return (
      <>
        <Stat profile={profiles.profile} />
        <Stat profile={profiles.profile2} />
      </>
    )
  }

  const Stat = ({ profile }) => {
    const stats = profile.stats

    const totalPlaytime = () => minutesToHours(stats.total_playtime, 'ceil')
    const avgTimePlayed = () => minutesToHours(stats.avg_playtime, 'ceil')
    const totalPrice = () => toLocale(stats.total_price, i18n.resolvedLanguage, true, 0)
    const avgPrice = () => toLocale(stats.avg_price, i18n.resolvedLanguage, true, 2)
    const avgCost = () => toLocale(stats.avg_cost, i18n.resolvedLanguage, true, 2)

    return (
      <div>
        <Container fluid className='mt-5 mb-5'>
          <Row className='d-flex justify-content-center'>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span>{avgCost()}/h</span>
                <span className='game-worth-label'>{t('data.cost')}</span>
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span>{avgPrice()}</span>
                <span className='game-worth-label'>{t('data.price_game')}</span>
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span>{avgTimePlayed()}h</span>
                <span className='game-worth-label'>{t('data.hours_game')}</span>
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
              <p className='game-worth text-center'>
                <span>{totalPrice()}</span>
                <span className='game-worth-label'>{t('data.spent_games')}</span>
              </p>

            </Col>
            <Col sm={12} md={6} lg={1} className='text-center'>
              <p className='game-worth text-center'>
                <span>{totalPlaytime()}h</span>
                <span className='game-worth-label'>{t('data.hours_played')}</span>
              </p>
            </Col>

            <Col lg={3} id='profile-col'>
              <Row className='text-center align-items-center'>
                <Col lg={7} xs={9} className='text-end mb-4'>
                  <Player profile={profile} t={t} i18n={i18n} />
                </Col>
                <Col lg={2} xs={2} className='text-start justify-content-center'>
                  <Avatar profile={profile} />
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </div>
    )
  }

  if (!isLoaded) {
    return <LoadingStats />
  } else if (isLoaded && userExists) {
    return <StatsData />
  }
}
