import React from 'react'
import { Accordion, Image } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import '../assets/css/about.css'
import usePageMeta from '../controllers/pageMeta.controller'

export default function About () {
  const { t } = useTranslation('global')

  const faqGeneral = [
    { index: 1, title: t('faq.what_is'), description: t('faq.what_is_description') },
    { index: 2, title: t('faq.change_price'), description: t('faq.change_price_description') },
    { index: 3, title: t('faq.languages'), description: t('faq.languages_description'), images: ['change_language.png'] },
    { index: 4, title: t('faq.currencies'), description: t('faq.currencies_description') },
    { index: 5, title: t('faq.help_us'), description: t('faq.help_us_description'), links: [{ href: process.env.REACT_APP_PAYPAL_LINK, target: '_blank', text: t('faq.make_donation_paypal') }] },
    { index: 6, title: t('faq.reload_data'), description: t('faq.reload_data_description') },
    { index: 7, id: 'find_profile', title: t('faq.search_id'), description: t('faq.search_id_description'), images: ['steam_profile_url.png'] },
    { index: 8, id: 'privacy', title: t('faq.change_privacy'), description: t('faq.change_privacy_description'), images: ['public_profile_steam.png'] },
    { index: 9, title: t('faq.how_login'), description: t('faq.how_login_description'), images: ['how_log_in.png'] },
    { index: 10, title: t('faq.premium_user'), description: t('faq.premium_user_description') }
  ]

  const faqSections = [
    { index: 11, title: t('faq.profile'), description: t('faq.profile_description') },
    { index: 12, title: t('faq.compare_profiles'), description: t('faq.compare_profiles_description'), images: ['compare_profiles.png'] },
    { index: 13, title: t('faq.free_games'), description: t('faq.free_games_description') },
    { index: 14, title: t('faq.offer_games'), description: t('faq.offer_games_description') },
    { index: 15, title: t('faq.cheap_keys'), description: t('faq.cheap_keys_description') },
    { index: 16, title: t('faq.search_cheap_keys'), description: t('faq.search_cheap_keys_description') }
  ]

  const faqOther = [
    { index: 17, title: t('faq.privacy_policy'), description: t('faq.privacy_policy_description') },
    { index: 18, title: t('faq.thanks_to'), description: t('faq.thanks_to_description') }
  ]

  // Merge the 3 arrays into one
  const faq = faqGeneral.concat(faqSections, faqOther)

  // If the page has an anchor, search the anchor inside faq array by id and get the description
  const anchor = window.location.hash.substring(1)
  const anchorItem = faq.find(faq => faq.id === anchor)
  const anchorIndex = anchorItem ? anchorItem.index : null

  // Scroll to anchor
  const scroll = React.useCallback(node => {
    if (node !== null && anchorIndex) {
      window.scrollTo({
        top: node.getBoundingClientRect().top,
        behavior: 'smooth'
      })
    }
    // eslint-disable-next-line
  }, [])

  const FaqSection = ({ header, items }) => {
    return (
      <div>
        <div className='faqHeader'>{t(`faq.${header}`)}</div>
        {items.map((tab, index) => {
          const props = anchorIndex === tab.index ? { ref: scroll } : {}
          return (
            <Accordion {...props} key={index} defaultActiveKey={anchorIndex === tab.index && anchorIndex}>
              <Accordion.Item eventKey={tab.index}>
                <Accordion.Header>{tab.title}</Accordion.Header>
                <Accordion.Body>
                  <Trans i18nKey={tab.description} />

                  {tab.links && tab.links.map((link, index) => (
                    <div key={index}>
                      <br />
                      <a href={link.href} target={link.target || '_blank'}>{link.text}</a>
                    </div>
                  ))}

                  {tab.images && tab.images.map((image, index) => (
                    <div key={index}>
                      <br />
                      <Image src={require(`../assets/images/about/${image}`)} fluid />
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <br />
            </Accordion>
          )
        })}
      </div>
    )
  }

  usePageMeta(t('title.about'), t('description.about'))

  return (
    <div>
      <section className='jumbotron jumbotron-compare text-center container-compare-info mb-4'>
        <div className='container-fluid'>
          <div className='align-items-center row'>
            <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
              <span className='compare-title align-bottom'>{t('faq.title')}</span>
            </div>
          </div>
        </div>
      </section>

      <main role='main' className='container'>
        <div className='starter-template'>
          <div className='container'>
            <FaqSection items={faqGeneral} header='general' />
            <FaqSection items={faqSections} header='sections' />
            <FaqSection items={faqOther} header='other' />
          </div>

        </div>

        <br /><br />

      </main>
    </div>
  )
}
