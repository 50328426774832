import { Col, Container, Image, Placeholder, Table } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import imageNotFound from '../../../assets/images/profile/image-not-found.png'
import { getCurrency } from '../../../controllers/currency.controller'

const LoadingGames = () => {
  const { t } = useTranslation('global')
  const iterator = [...Array(parseInt(process.env.REACT_APP_LOADING_PROFILE_NUM_GAMES) || 4)]

  const loadingBlock = (i) =>
    <tr key={i}>
      <td className='game-position align-middle text-center'>
        <h3>{i + 1}</h3>
      </td>
      <td>
        <Image src={imageNotFound} className='game-image' />
        <h3 className='game-name'>
          <Placeholder animation='wave'>
            <Placeholder xs={6} className='' />
          </Placeholder>
        </h3>
        <h4 className='game-playtime'>
          <Placeholder animation='wave'>
            <Placeholder xs={3} className='' />
          </Placeholder>
        </h4>
        <h4 className='game-playtime'>
          <Placeholder animation='wave'>
            <Placeholder xs={3} className='' />
          </Placeholder>
        </h4>
      </td>
      <td className='align-middle text-center'>
        <Col lg={6} className='mx-auto'>
          <Placeholder animation='wave'>
            <Placeholder xs={6} className='' />
          </Placeholder>
        </Col>
      </td>
      <td className='align-middle text-center'>
        <h3 className='game-hours'>
          <Placeholder animation='wave'>
            <Placeholder xs={6} />
          </Placeholder>
        </h3>
      </td>
      <td className='align-middle text-center'>
        <div className='game-worth'>
          <span className='game-final-worth'>
            <Placeholder animation='wave'>
              <Placeholder xs={12} />
            </Placeholder>
          </span>
          <span className='game-worth-label'>{getCurrency()}/h</span>
        </div>
      </td>
    </tr>

  return (
    <>
      <Container fluid>
        <Table striped>
          <thead className='thead-dark text-center'>
            <tr>
              <th>#</th>
              <th>{t('profile.table_games')}</th>
              <th>{t('profile.table_purchase_price')}</th>
              <th>{t('profile.table_hours')}</th>
              <th>{t('profile.table_cost_hour')}</th>
            </tr>
          </thead>
          <tbody>
            {iterator.map((value, index) => loadingBlock(index))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

export default LoadingGames
