import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'react-bootstrap'
import '../../assets/css/keys.css'
import LoadingGames from '../loading/keys/LoadingGames'
import Game from './Game'
import usePageMeta from '../../controllers/pageMeta.controller'

export default function GamesKeys () {
  const { t } = useTranslation('global')

  const [gamesTopFeatured, setGamesTopFeatured] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  // Top keys with featured ones
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL_API}/keys/top-featured`, {
      mode: 'cors'
    }
    )
      .then(res => res.json())
      .then((data) => {
        if (data.error) {
          setHasError(true)
        } else {
          setGamesTopFeatured(data)
        }

        setIsLoaded(true)
      })
  }, [])

  const NotFound = () => {
    return (
      <Container className='text-center'>
        <h3>{t('error.no_games_found')}</h3>
      </Container>
    )
  }

  const Games = () => {
    if (hasError) {
      return <NotFound />
    }

    return (
      isLoaded
        ? <Game games={gamesTopFeatured} />
        : <LoadingGames />
    )
  }

  usePageMeta(t('title.keys_cheap'), t('description.keys_cheap'))

  return (
    <>
      <section className='jumbotron jumbotron-compare text-center container-compare-info mb-5'>
        <div className='container-fluid'>
          <div className='align-items-center row'>
            <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
              <span className='compare-title align-bottom'>{t('games.best_cheap_keys')}</span>
            </div>
          </div>
        </div>
      </section>

      <Container fluid='md' className='keys-cheap'>
        <Row>
          <Games />
        </Row>
        <br /><br />
      </Container>
    </>
  )
}
