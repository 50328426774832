import { Col, Container, Row } from 'react-bootstrap'
import React from 'react'

const LoadingStats = () => {
  return (
    <div>
      <Container fluid className='mt-5 mb-5'>
        <Row className='d-flex justify-content-center'>
          <Col sm={12} md={6} lg={2} className=' text-center'>
            <div className='container align-items-center justify-content-center' />
          </Col>
          <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
            <div className='container align-items-center justify-content-center' />
          </Col>
          <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
            <div className='container align-items-center justify-content-center' />
          </Col>
          <Col sm={12} md={6} lg={2} className='game-stat-zone text-center'>
            <div className='container align-items-center justify-content-center' />
          </Col>
          <Col sm={12} md={6} lg={2} className='text-center'>
            <div className='container align-items-center justify-content-center' />
          </Col>
          <Col sm={12} md={6} lg={2} className='text-center'>
            <div className='container align-items-center justify-content-center' />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LoadingStats
