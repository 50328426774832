import React from 'react'
import { useTranslation } from 'react-i18next'
import 'flag-icons/css/flag-icons.min.css'
import '@fontsource/oswald'
import '../../assets/css/summary.css'

import { Col, Container, Row } from 'react-bootstrap'
import NotFound from '../errors/NotFound'
import LoadingSummary from '../loading/profile/LoadingSummary'

export default function CompareSummary ({ profiles, userExists, isLoaded }) {
  const { t } = useTranslation('global')

  const profile = profiles.profile
  const profile2 = profiles.profile2

  const SummaryData = () => {
    return (
      <section className='jumbotron text-center container-profile-info'>
        <Container fluid>
          <Row className='align-items-center'>
            <Col lg={12}>
              <h1 className='jumbotron-heading align-items-center'>{t('app.comparison_between')} {profile.name} {t('general.and')} {profile2.name}</h1>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  if (isLoaded && !userExists) {
    return <NotFound />
  } else if (isLoaded && userExists) {
    return <SummaryData />
  } else {
    return <LoadingSummary />
  }
}
