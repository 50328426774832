import { Col, Row } from 'react-bootstrap'
import ImageWithFallback from '../profile/Image'
import imageNotFound from '../../assets/images/profile/image-not-found.png'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toLocale } from '../../controllers/converter/money.controller'

const Game = ({ games }) => {
  const { t, i18n } = useTranslation('global')

  return (
    <>
      {games.map((game, index) => (
        <Col key={index} xl={6} xs={12} sm={12} className='col-game mx-auto col-game-keys'>
          <Row className='m-0'>
            <Col lg={4} xs={12} sm={4} className='container-game-image text-center'>
              {ImageWithFallback(imageNotFound, game)}
            </Col>
            <Col lg={8} xs={12} sm={8} className='container-game-info'>
              <Row>
                <Col lg={6} xs={12} sm={7}>
                  <h3 className='game-name'>{game.name}</h3>

                  <h4 className='game-playtime'>{game.genres}</h4>

                  <h4 className='game-buy-container'>
                    <a href={game.key.link} className='game-buy' target='_blank' rel='noreferrer'>{t('buy.at')} {game.key.store}</a>
                  </h4>
                </Col>
                <Col lg={5} xs={12} sm={5}>
                  <div className='game-worth'>
                    <span className='game-final-worth'>{toLocale(game.key.price, i18n.resolvedLanguage, true, 2)}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </>
  )
}

export default Game
