import React from 'react'
import { useTranslation } from 'react-i18next'
import 'flag-icons/css/flag-icons.min.css'
import '../../assets/css/game.css'
import imageNotFound from '../../assets/images/profile/image-not-found.png'

import { Col, InputGroup } from 'react-bootstrap'
import ImageWithFallback from './Image'
import { GameCost, GameKey, Hltb } from '../../controllers/game.controller'
import { toLocale } from '../../controllers/converter/money.controller'
import useForm from '../../controllers/prices.controller'
import { minutesToHours } from '../../controllers/converter/playtime.controller'
import { getCurrency } from '../../controllers/currency.controller'

export default function Game ({ game, profile }) {
  const { t } = useTranslation('global')

  const UserGamePrice = () => {
    const { handleChange } = useForm((game.price), game.steam_id, profile.steam_id)

    return (
      <InputGroup>
        <input
          className='form-control'
          type='number'
          step='0.01'
          id='price'
          name='price'
          placeholder='Price'
          defaultValue={toLocale(game.price, 'en', true, 2, false, true, false)}
          onBlur={handleChange}
          onWheel={(e) => e.target.blur()}
        />
        <span className='input-group-text'>{getCurrency()}</span>
      </InputGroup>
    )
  }

  return (
    <tr key={game.game.steam_id}>
      <td className='game-position align-middle text-center'>
        <h3>{game.index}</h3>
      </td>
      <td>
        {ImageWithFallback(imageNotFound, game.game)}
        <h3 className='game-name'>{game.game.name}</h3>
        <h4 className='game-playtime'>
          <Hltb game={game.game} t={t} />
        </h4>
        <h4 className='game-playtime'>
          <GameKey game={game.game} t={t} />
        </h4>
      </td>
      <td className='align-middle text-center'>
        <Col lg={6} className='mx-auto'>
          <UserGamePrice game={game.game} t={t} />
        </Col>
      </td>
      <td className='align-middle text-center'>
        <h3 className='game-hours'>{minutesToHours(game.playtime, 'ceil')}</h3>
      </td>
      <td className='align-middle text-center'>
        <div className='game-worth'>
          <span className='game-final-worth'>
            <GameCost game={game} />
          </span>
          <span className='game-worth-label'>{getCurrency()}/h</span>
        </div>
      </td>
    </tr>
  )
}
