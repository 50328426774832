import React from 'react'
import { Pagination } from 'react-bootstrap'

export default function Paginate ({ userGames, setPagination }) {
  const maxGamesPerPage = parseInt(process.env.PAGINATION_MAX_GAMES_PER_PAGE) || 50
  const maxPaginationPages = parseInt(process.env.PAGINATION_MAX_PAGES) || 50
  const totalGames = userGames ? userGames.length : 0
  const disabledPagination = totalGames <= maxGamesPerPage
  const maxUserPagination = Math.ceil(totalGames / maxGamesPerPage)

  const [active, setActive] = React.useState(1)
  const [currPage, setCurrPage] = React.useState(1)

  const afterPageClicked = (number) => {
    setActive(number)
    setCurrPage(number)

    // Send pagination to parent controller
    setPagination([number > 1 ? ((number * maxGamesPerPage) - maxGamesPerPage) : 0, maxGamesPerPage * number])
  }

  const PaginationNumbers = () => {
    const [items, setItems] = React.useState([])

    React.useEffect(() => {
      const items = []
      for (let number = 1; number <= Math.ceil(totalGames / maxGamesPerPage); number++) {
        // If current iteration is equal to maxPaginationPages, break
        if (number > maxPaginationPages) {
          break
        }

        items.push(
          <Pagination.Item key={number} active={number === active} onClick={() => afterPageClicked(number)}>
            {number}
          </Pagination.Item>
        )
      }

      setItems(items)
    }, [])

    // Show previous page
    if (currPage <= maxUserPagination && currPage > maxPaginationPages + 1) {
      const prevPage = currPage - 1
      items.push(
        <Pagination.Item key={prevPage} active={prevPage === active} onClick={() => afterPageClicked(prevPage)}>
          {prevPage}
        </Pagination.Item>
      )
    }

    // Show current page
    if (currPage > 1 && currPage < maxUserPagination && currPage > maxPaginationPages) {
      items.push(
        <Pagination.Item key={currPage} active={currPage === active} onClick={() => afterPageClicked(currPage)}>
          {currPage}
        </Pagination.Item>
      )
    }

    // Show next page
    if (currPage < maxUserPagination - 1 && currPage >= maxPaginationPages) {
      const nextPage = currPage + 1
      items.push(
        <Pagination.Item key={nextPage} active={nextPage === active} onClick={() => afterPageClicked(nextPage)}>
          {nextPage}
        </Pagination.Item>
      )
    }

    return (
      <Pagination>
        <Pagination.First onClick={() => afterPageClicked(1)} disabled={disabledPagination || currPage === 1} />
        <Pagination.Prev onClick={() => afterPageClicked(currPage - 1)} disabled={disabledPagination || currPage === 1} />

        {items}

        <LastPaginationNumbers />

        <Pagination.Next onClick={() => afterPageClicked(currPage + 1)} disabled={disabledPagination || currPage === maxUserPagination} />
        <Pagination.Last onClick={() => afterPageClicked(maxUserPagination)} disabled={disabledPagination || currPage === maxUserPagination} />
      </Pagination>
    )
  }

  // Show the last page if the number of games is more than 250
  const LastPaginationNumbers = () => {
    if (totalGames > maxPaginationPages * maxGamesPerPage) {
      return (
        <>
          <Pagination.Ellipsis disabled />
          <Pagination.Item key={Math.ceil(totalGames / maxGamesPerPage)} active={Math.ceil(totalGames / maxGamesPerPage) === active} onClick={() => afterPageClicked(Math.ceil(totalGames / maxGamesPerPage))}>
            {Math.ceil(totalGames / maxGamesPerPage)}
          </Pagination.Item>
        </>
      )
    }
  }

  return (
    <PaginationNumbers />
  )
}
