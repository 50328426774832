import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CompareSummary from './ComapreSummary'
import CompareStats from './CompareStats'
import CompareGames from './CompareGames'
import { notFoundMessage } from '../../config/constants'
import usePageMeta from '../../controllers/pageMeta.controller'

export default function CompareProfile () {
  const { t } = useTranslation('global')
  const params = useParams()
  const profileId = params.profile
  const profileId2 = params.profile2

  const [profiles, setProfile] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [userExists, setUserExists] = React.useState(true)

  React.useEffect(() => {
    setIsLoaded(false)
    fetch(`${process.env.REACT_APP_BASE_URL_API}/profile/${profileId}/${profileId2}`, {
      mode: 'cors'
    }
    )
      .then(res => res.json())
      .then((data) => {
        if (data.error && data.error === notFoundMessage) {
          setUserExists(false)
        }

        setProfile(data)
        setIsLoaded(true)
      })
  }, [profileId, profileId2])

  usePageMeta(t('title.compare'), t('description.compare'))

  return (
    <div>
      <CompareSummary profiles={profiles} userExists={userExists} isLoaded={isLoaded} />
      <CompareStats profiles={profiles} userExists={userExists} isLoaded={isLoaded} />
      <CompareGames profiles={profiles} userExists={userExists} isLoaded={isLoaded} />
    </div>
  )
}
