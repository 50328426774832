// Convert playtime from minutes to hours, with no decimals
const minutesToHours = (seconds, round = null, compare = null) => {
  if (compare && (isNaN(seconds) || seconds === null)) {
    return ''
  }

  if (round === 'ceil') {
    return Math.ceil(seconds / 60)
  } else {
    return Math.floor(seconds / 60)
  }
}

export {
  minutesToHours
}
