import { steamLevelStyle, steamSubLevelStyle } from '../../../controllers/steamLevels.controller'
import { formatFullDate } from '../../../controllers/converter/date.controller'
import ReactTimeAgo from 'react-time-ago'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Player ({ profile }) {
  const { t, i18n } = useTranslation('global')

  const totalGames = (stats) => {
    return typeof stats !== 'undefined' && typeof stats.total_games !== 'undefined' ? stats.total_games : 0
  }

  return (
    <>
      <span className={`flag-icon fi fi-${(profile.country || '').toLowerCase()}`} />
      <span className='profile-name'>{profile.name}</span>
      <div className={`friendPlayerLevel lvl_${steamLevelStyle(profile.level)} lvl_plus_${steamSubLevelStyle(profile.level)}`}>
        <span className='friendPlayerLevelNum'>{profile.level}</span>
      </div>
      <br />
      <span className='profile-age'>{t('profile.member_since')}: {formatFullDate(profile.register_date, i18n.language)}</span>
      <br />
      <span className='profile-total-games'>{totalGames(profile.stats)} {t('profile.games_in_library')}</span>
      <br />
      <span className='profile-age'>{t('calendar.profile_updated_ago')}: <ReactTimeAgo date={Date.parse(profile.updated_on)} locale={i18n.resolvedLanguage} /></span>
    </>
  )
}
