import React from 'react'
import { useTranslation } from 'react-i18next'
import 'flag-icons/css/flag-icons.min.css'
import '../../assets/css/game.css'
import imageNotFound from '../../assets/images/profile/image-not-found.png'

import ImageWithFallback from '../profile/Image'
import { GameCost, GameKey, Hltb } from '../../controllers/game.controller'
import { minutesToHours } from '../../controllers/converter/playtime.controller'
import { getCurrency } from '../../controllers/currency.controller'

export default function CompareGame ({ game }) {
  const { t } = useTranslation('global')

  return (
    <tr key={game.game.steam_id}>
      <td className='game-position align-middle text-center'>
        <h3>{game.index}</h3>
      </td>
      <td>
        {ImageWithFallback(imageNotFound, game.game)}
        <h3 className='game-name'>{game.game.name}</h3>
        <h4 className='game-playtime'>
          <Hltb game={game.game} t={t} />
        </h4>
        <h4 className='game-playtime'>
          <GameKey game={game.game} t={t} />
        </h4>
      </td>
      <td className='align-middle text-center'>
        <h3 className='game-hours'>{minutesToHours(game.playtime, 'ceil', true)}</h3>
      </td>
      <td className='align-middle text-center'>
        <h3 className='game-hours'>{minutesToHours(game.playtime2, 'ceil', true)}</h3>
      </td>
      <td className='align-middle text-center'>
        <div className='game-worth'>
          <span className='game-final-worth'>
            <GameCost game={game} />
          </span>
          <span className='game-worth-label'>{getCurrency()}/h</span>
        </div>
      </td>
      <td className='align-middle text-center'>
        <div className='game-worth'>
          <span className='game-final-worth'>
            <GameCost game={game} compare />
          </span>
          <span className='game-worth-label'>{getCurrency()}/h</span>
        </div>
      </td>
    </tr>
  )
}
