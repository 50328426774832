import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
import 'lato-font'
import './assets/css/global.css'
import Navigation from './components/Navigation'

import React from 'react'
import usePageTracking from './usePageTracking'
import loginDetector from './loginDetector'

function App () {
  usePageTracking()
  loginDetector()

  return (
    <div id='page-container'>
      <Header />

      <Navigation />

      <Footer />
    </div>
  )
}

export default App
