const topGamesConfig = {
  backgroundColor: [
    'rgba(17, 120, 153, 0.5)',
    'rgba(19, 149, 186, 0.5)',
    'rgba(162, 184, 108, 0.5)',
    'rgba(235, 200, 68, 0.5)',
    'rgba(236, 170, 56, 0.5)',
    'rgba(241, 108, 32, 0.5)',
    'rgba(217, 78, 31, 0.5)',
    'rgba(192, 46, 29, 0.5)',
    'rgba(15, 91, 120, 0.5)',
    'rgba(13, 60, 85, 0.5)'
  ],
  borderColor: [
    'rgba(17, 120, 153, 1)',
    'rgba(19, 149, 186, 1)',
    'rgba(162, 184, 108, 1)',
    'rgba(235, 200, 68, 1)',
    'rgba(236, 170, 56, 1)',
    'rgba(241, 108, 32, 1)',
    'rgba(217, 78, 31, 1)',
    'rgba(192, 46, 29, 1)',
    'rgba(15, 91, 120, 1)',
    'rgba(13, 60, 85, 1)'
  ],
  borderWidth: 1,
  hoverBackgroundColor: [
    'rgba(17, 120, 153, 1)',
    'rgba(19, 149, 186, 1)',
    'rgba(162, 184, 108, 1)',
    'rgba(235, 200, 68, 1)',
    'rgba(236, 170, 56, 1)',
    'rgba(241, 108, 32, 1)',
    'rgba(217, 78, 31, 1)',
    'rgba(192, 46, 29, 1)',
    'rgba(15, 91, 120, 1)',
    'rgba(13, 60, 85, 1)'
  ],
  cutout: '70%',
  getPlugins: (percents) => {
    return {
      legend: {
        display: true,
        position: 'right',
        align: 'start',
        labels: {
          font: {
            family: 'Lato',
            size: 13
          }
        }
      },
      datalabels: {
        formatter: function (value, context) {
          return percents[context.dataIndex] + '%'
        },
        font: {
          family: 'Lato',
          weight: 'bold',
          size: 15
        }
      }
    }
  },
  onResize: (myChart) => {
    myChart.options.plugins.legend.position = (window.innerWidth >= 700 ? 'right' : 'top')
    myChart.options.plugins.legend.align = (window.innerWidth >= 700 ? 'center' : 'start')
  }
}

export {
  topGamesConfig
}
