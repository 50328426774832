import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import About from './About'
import Profile from './profile/Profile'
import News from './keys/News'
import GamesKeys from './keys/Games'
import GamesKeysSearch from './keys/Search'
import Charts from './charts/Chart'
import Stats from './Stats'
import CompareProfile from './compare/CompareProfile'

export default function Navigation () {
  return (
    <div id='content-wrap'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='profile/:profile' element={<Profile />} />
        <Route path='profile/:profile/charts' element={<Charts />} />
        <Route path='profile/:profile/:profile2' element={<CompareProfile />} />
        <Route path='news' element={<News />} />
        <Route path='stats' element={<Stats />} />
        <Route path='keys/top' element={<GamesKeys />} />
        <Route path='keys/search' element={<GamesKeysSearch />} />
      </Routes>
    </div>
  )
}
