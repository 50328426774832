import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import globalEs from './locales/es/global.json'
import globalEn from './locales/en/global.json'

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // detection: { order: ['navigator'] },
    resources: {
      es: {
        global: globalEs
      },
      en: {
        global: globalEn
      }
    },
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
