import React from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)

const usePageTracking = () => {
  const location = useLocation()
  const currentPage = location.pathname + location.search

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: currentPage })
  }, [location])
}

export default usePageTracking
