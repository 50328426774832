import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar, Container } from 'react-bootstrap'
import '../assets/css/footer.css'

export default function Header () {
  const { t } = useTranslation('global')
  const currentYear = new Date().getFullYear()

  return (
    <footer id='footer'>
      <Navbar variant='light' bg='light' className='navbar-footer'>
        <Container>
          <Navbar.Text>
            <div className='m-1 m-lg-0 text-justify'>
              {t('app.app_name')}@{currentYear} {t('headers.development_donation')}
              <a href={process.env.REACT_APP_PAYPAL_LINK} target='_blank' rel='noreferrer'>Paypal</a>
            </div>
          </Navbar.Text>
          <Navbar.Text>
            <div className='m-1 m-lg-0 text-justify'>
              Logo design by Alejandro Esteller
            </div>
          </Navbar.Text>
        </Container>
      </Navbar>
    </footer>
  )
}
