import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import '../../assets/css/games.css'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { minutesToHours } from '../../controllers/converter/playtime.controller'
import { toAbsolute, toLocale } from '../../controllers/converter/money.controller'
import NotFound from '../errors/NotFound'
import LoadingDots from '../loading/global/LoadingDots'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import Player from './Player'
import { topGamesConfig } from '../../config/charts'
import { numberToMoreOrLessString } from '../../controllers/converter/dataToString.controller'

export default function Charts () {
  const { t, i18n } = useTranslation('global')
  const params = useParams()
  const profileId = params.profile

  const [isLoaded, setIsLoaded] = React.useState(false)
  const [hasGames, setHasGames] = React.useState(false)
  const [topPlayers, setTopPlayers] = React.useState([])
  const [userStats, setUserStats] = React.useState([])
  const [mostPlayedGames, setMostPlayedGames] = React.useState({ options: {}, series: [] })
  const [opt, setOpt] = React.useState({})

  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

  React.useEffect(() => {
    let status
    fetch(`${process.env.REACT_APP_BASE_URL_API}/profile/${profileId}/charts`, {
      mode: 'cors'
    }).then(res => {
      status = res.status
      return res.json()
    }).then((data) => {
      if (status === 200) {
        setMostPlayedGames({
          labels: data.topGamesByPlaytime.map(game => game.game.name),
          datasets: [
            {
              data: data.topGamesByPlaytime.map(game => minutesToHours(game.playtime)),
              backgroundColor: topGamesConfig.backgroundColor,
              borderColor: topGamesConfig.borderColor,
              hoverBackgroundColor: topGamesConfig.hoverBackgroundColor,
              borderWidth: topGamesConfig.borderWidth
            }
          ]
        })

        setOpt({
          onResize: topGamesConfig.onResize,
          cutout: topGamesConfig.cutout
        })

        setTopPlayers(data.topPlayersByPlaytime)

        setUserStats(data.userStats)

        setIsLoaded(true)
        setHasGames(true)
      } else {
        setIsLoaded(true)
        setHasGames(false)
      }
    })
  }, [profileId])

  const Charts = () => {
    return (
      <>
        <section className='jumbotron jumbotron-compare text-center container-compare-info'>
          <div className='container-fluid'>
            <div className='align-items-center row'>
              <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
                <span className='compare-title align-bottom'>{t('stats.most_played_games')}</span>
              </div>
            </div>
          </div>
        </section>

        <Container className='chart-container p-4' style={{ maxWidth: '700px' }}>
          <Doughnut data={mostPlayedGames} options={opt} />
        </Container>

        <section className='jumbotron jumbotron-compare text-center container-compare-info'>
          <div className='container-fluid'>
            <div className='align-items-center row'>
              <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
                <span className='compare-title align-bottom'>{t('menu.stats')}</span>
              </div>
            </div>
          </div>
        </section>

        <Row className='m-3 text-center'>
          <Col lg={12}>
            <h3>{t('stats.you_spent')} <span>{toLocale(userStats.total_price, i18n.resolvedLanguage, true, 0)}</span>, <span className='text-danger'>{toAbsolute(userStats.avg_price_percent)}% {numberToMoreOrLessString(userStats.avg_price_percent, t)}</span> {t('stats.than_average')}</h3>
            <h3>{t('stats.you_played')} <span>{minutesToHours(userStats.total_playtime)}h</span>, <span className='text-success'>{toAbsolute(userStats.avg_playtime_percent)}% {numberToMoreOrLessString(userStats.avg_playtime_percent, t)}</span> {t('stats.than_average')}</h3>
            <h3>{t('stats.you_have_total_games')} <span>{userStats.total_games}</span> {t('general.games')}, <span>{userStats.f2p_percent}%</span> {t('stats.of_them_f2p')}</h3>
          </Col>
        </Row>

        <section className='jumbotron jumbotron-compare text-center container-compare-info'>
          <div className='container-fluid'>
            <div className='align-items-center row'>
              <div className='justify-content-center jumbotron-heading align-items-center my-auto col'>
                <span className='compare-title align-bottom'>{t('charts.top_players_by_playtime')}</span>
              </div>
            </div>
          </div>
        </section>
        <br />

        <Container>
          <Row>
            <Col lg={12}>
              <Table striped responsive>
                <thead>
                  <tr className='table-dark text-center'>
                    <th>#</th>
                    <th>{t('stats.player')}</th>
                    <th>{t('stats.total_playtime')}</th>
                    <th>{t('stats.total_spent')}</th>
                    <th>{t('stats.user_since')}</th>
                  </tr>
                </thead>
                <tbody>
                  <Player topPlayers={topPlayers} i18n={i18n} t={t} profileId={profileId} />
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  if (!isLoaded) {
    return (
      <LoadingDots />
    )
  } else if (isLoaded && hasGames) {
    return (
      <Charts />
    )
  } else if (isLoaded && !hasGames) {
    return (
      <NotFound />
    )
  }
}
